export const serverAddress = "https://ptracker.onrender.com";
// export const serverAddress = "http://localhost:3000";
export const CREATE_NEW_PARTTIME = "CREATE_NEW_PARTTIME";
export const CREATE_NEW_PARTTIME_PENDING = "CREATE_NEW_PARTTIME_PENDING";
export const CREATE_NEW_PARTTIME_FULFILLED = "CREATE_NEW_PARTTIME_FULFILLED";
export const CREATE_NEW_PARTTIME_REJECTED = "CREATE_NEW_PARTTIME_REJECTED";

export const GET_ALL_PARTTIME = "GET_ALL_PARTTIME";
export const GET_ALL_PARTTIME_PENDING = "GET_ALL_PARTTIME_PENDING";
export const GET_ALL_PARTTIME_FULFILLED = "GET_ALL_PARTTIME_FULFILLED";
export const GET_ALL_PARTTIME_REJECTED = "GET_ALL_PARTTIME_REJECTED";

export const LOGIN_DATA = "LOGIN_DATA";
export const LOGIN_DATA_FULFILLED = "LOGIN_DATA_FULFILLED";
export const LOGIN_DATA_PENDING = "LOGIN_DATA_PENDING";
export const LOGIN_DATA_REJECTED = "LOGIN_DATA_REJECTED";

export const INITIATE_RESET = "INITIATE_RESET";
export const INITIATE_RESET_FULFILLED = "INITIATE_RESET_FULFILLED";
export const INITIATE_RESET_PENDING = "INITIATE_RESET_PENDING";
export const INITIATE_RESET_REJECTED = "INITIATE_RESET_REJECTED";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_FULFILLED = "RESET_PASSWORD_FULFILLED";
export const RESET_PASSWORD_PENDING = "RESET_PASSWORD_PENDING";
export const RESET_PASSWORD_REJECTED = "RESET_PASSWORD_REJECTED";

export const VALIDATE_TOKEN = "VALIDATE_TOKEN";
export const VALIDATE_TOKEN_FULFILLED = "VALIDATE_TOKEN_FULFILLED";
export const VALIDATE_TOKEN_PENDING = "VALIDATE_TOKEN_PENDING";
export const VALIDATE_TOKEN_REJECTED = "VALIDATE_TOKEN_REJECTED";

export const CREATE_NEW_SHIFT = "CREATE_NEW_SHIFT";
export const CREATE_NEW_SHIFT_PENDING = "CREATE_NEW_SHIFT_PENDING";
export const CREATE_NEW_SHIFT_FULFILLED = "CREATE_NEW_SHIFT_FULFILLED";
export const CREATE_NEW_SHIFT_REJECTED = "CREATE_NEW_SHIFT_REJECTED";

export const GET_ALL_SHIFTS = "GET_ALL_SHIFTS";
export const GET_ALL_SHIFTS_PENDING = "GET_ALL_SHIFTS_PENDING";
export const GET_ALL_SHIFTS_FULFILLED = "GET_ALL_SHIFTS_FULFILLED";
export const GET_ALL_SHIFTS_REJECTED = "GET_ALL_SHIFTS_REJECTED";

export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_FULFILLED = "REGISTER_USER_FULFILLED";
export const REGISTER_USER_PENDING = "REGISTER_USER_PENDING";
export const REGISTER_USER_REJECTED = "REGISTER_USER_REJECTED";

export const UPDATE_PARTTIME = "UPDATE_PARTTIME";
export const UPDATE_PARTTIME_FULFILLED = "UPDATE_PARTTIME_FULFILLED";
export const UPDATE_PARTTIME_PENDING = "UPDATE_PARTTIME_PENDING";
export const UPDATE_PARTTIME_REJECTED = "UPDATE_PARTTIME_REJECTED";

export const UPDATE_SHIFT = "UPDATE_SHIFT";
export const UPDATE_SHIFT_FULFILLED = "UPDATE_SHIFT_FULFILLED";
export const UPDATE_SHIFT_PENDING = "UPDATE_SHIFT_PENDING";
export const UPDATE_SHIFT_REJECTED = "UPDATE_SHIFT_REJECTED";

export const DELETE_SHIFT = "DELETE_SHIFT";
export const DELETE_SHIFT_FULFILLED = "DELETE_SHIFT_FULFILLED";
export const DELETE_SHIFT_PENDING = "DELETE_SHIFT_PENDING";
export const DELETE_SHIFT_REJECTED = "DELETE_SHIFT_REJECTED";

export const LOGOUT = "LOGOUT";
