import * as types from "../../constants";
import LoginAPI from "../../utils/api/userAPI";

export function authenticateUser(params) {
  return async (dispatch) => {
    dispatch({
      type: types.LOGIN_DATA_PENDING, // Dispatch the pending action
    });
    try {
      const response = await LoginAPI.signIn(params);
      dispatch({
        type: types.LOGIN_DATA_FULFILLED,
        payload: response.data,
      });
      return response.data;
    } catch (error) {
      dispatch({
        type: types.LOGIN_DATA_REJECTED,
        payload: error.message,
      });
      throw error;
    }
  };
}

export function initiateResetPassword(params) {
  return async (dispatch) => {
    dispatch({
      type: types.LOGIN_DATA_PENDING, // Dispatch the pending action
    });
    try {
      const response = await LoginAPI.initiateResetPassword(params);
      dispatch({
        type: types.LOGIN_DATA_FULFILLED,
        payload: response.data,
      });
      return response.data;
    } catch (error) {
      dispatch({
        type: types.LOGIN_DATA_REJECTED,
        payload: error.message,
      });
      throw error;
    }
  };
}

export function verifyOtpAndResetPassword(params) {
  return async (dispatch) => {
    dispatch({
      type: types.LOGIN_DATA_PENDING, // Dispatch the pending action
    });
    try {
      const response = await LoginAPI.verifyOtpAndResetPassword(params);
      dispatch({
        type: types.LOGIN_DATA_FULFILLED,
        payload: response.data,
      });
      return response.data;
    } catch (error) {
      dispatch({
        type: types.LOGIN_DATA_REJECTED,
        payload: error.message,
      });
      throw error;
    }
  };
}

//export function registerUser(params) {
// return {
// type: types.REGISTER_USER,
//payload: LoginAPI.signUp(params).then((response) => {
// return response.data;
//}),
// };
//}

export function registerUser(params) {
  return async (dispatch) => {
    dispatch({
      type: types.REGISTER_USER_PENDING, // Dispatch the pending action
    });
    try {
      const response = await LoginAPI.signUp(params);
      dispatch({
        type: types.REGISTER_USER_FULFILLED,
        payload: response.data,
      });
      return response.data;
    } catch (error) {
      dispatch({
        type: types.REGISTER_USER_REJECTED,
        payload: error.message,
      });
      throw error;
    }
  };
}

export function validateToken(params) {
  return {
    type: types.VALIDATE_TOKEN,
    payload: LoginAPI.validateToken(params).then((response) => {
      return response.data;
    }),
  };
}

export function logOut() {
  return {
    type: types.LOGOUT,
  };
}
