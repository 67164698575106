import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoutes";
import AuthRoutes from "./AuthRoutes.js";
import Sidebar from "../containers/Sidebar.js";

const Landing = lazy(() => import("../containers/Landing.js"));
const Login = lazy(() => import("../containers/Login.js"));
const SignUp = lazy(() => import("../containers/SignUp.js"));
const PTime = lazy(() => import("../containers/AddPtime"));
const ShiftPage = lazy(() => import("../containers/AddShift"));
const PList = lazy(() => import("../containers/Plist"));
const ShiftList = lazy(() => import("../containers/ShiftList"));
const ForgetPassword = lazy(() => import("../containers/ForgetPassword"));

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={"loading"}>
        <Routes>
          <Route
            path="/login"
            element={
              <AuthRoutes>
                <Login />
              </AuthRoutes>
            }
          />
          <Route
            path="/signup"
            element={
              <AuthRoutes>
                <SignUp />
              </AuthRoutes>
            }
          />
          <Route
            path="/forgot"
            element={
              <AuthRoutes>
                <ForgetPassword />
              </AuthRoutes>
            }
          />
          {/* <Route path="/login" element={<Login />} /> */}
          {/* <Route path="/signup" element={<SignUp />} /> */}
          {/* <Route path="/forgot" element={<ForgetPassword />} /> */}

          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Landing />

              </ProtectedRoute>
            }
          />
          <Route
            path="/addpt"
            element={
              <ProtectedRoute>
                <PTime />
              </ProtectedRoute>
            }
          />
          <Route
            path="/addshift"
            element={
              <ProtectedRoute>
                <ShiftPage />

              </ProtectedRoute>
            }
          />
          <Route
            path="/plist"
            element={
              <ProtectedRoute>
                <PList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/slist"
            element={
              <ProtectedRoute>
                <ShiftList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/test"
            element={
              <ProtectedRoute>
                <Sidebar />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default AppRoutes;
