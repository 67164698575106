import axios from "axios";
import { serverAddress } from "../../constants.js";

const api = {
  signUp: (params) => {
    if (params !== undefined) {
      let url = serverAddress + "/api/user/signup";
      return axios.post(url, params);
    }
  },
  signIn: (params) => {
    if (params !== undefined) {
      let url = serverAddress + `/api/user/signin`;
      return axios.post(url, params);
    }
  },
  initiateResetPassword: (params) => {
    if (params !== undefined) {
      let url = serverAddress + `/api/user/initiate-reset-password`;
      return axios.post(url, params);
    }
  },
  verifyOtpAndResetPassword: (params) => {
    if (params !== undefined) {
      let url = serverAddress + `/api/user/verify-otp-reset-password`;
      return axios.post(url, params);
    }
  },
};

export default api;
