import * as types from "../../constants";

const initialState = {
  loading: true,
  shiftData: [],
  shiftAddResponse: [],
  shiftList: [],
  message_error: "",
  receivedResponse: false,
  isshiftAdded: false,
  isshiftDeleted: false,
  isshiftListFetched: false,
  isUpdated: false,
};

export default function ShiftReducer(state = initialState, action) {
  switch (action.type) {
    case types.CREATE_NEW_SHIFT_FULFILLED:
      return {
        ...state,
        loading: false,
        shiftAddResponse: action.payload,
        receivedResponse: 200,
        isshiftAdded: true,
      };
    case types.CREATE_NEW_SHIFT_PENDING:
      return {
        ...state,
        loading: true,
        receivedResponse: false,
        isshiftAdded: false,
      };
    case types.CREATE_NEW_SHIFT_REJECTED:
      return {
        ...state,
        loading: false,
        receivedResponse: false,
        error: "An error occurred, please try again",
      };
    case types.UPDATE_SHIFT_FULFILLED:
      return {
        ...state,
        loading: false,
        receivedResponse: 200,
        isUpdated: true,
      };
    case types.UPDATE_SHIFT_PENDING:
      return {
        ...state,
        loading: true,
        receivedResponse: false,
        isUpdated: false,
      };
    case types.UPDATE_SHIFT_REJECTED:
      return {
        ...state,
        loading: false,
        receivedResponse: false,
        error: "An error occurred, please try again",
      };
    case types.GET_ALL_SHIFTS_FULFILLED:
      return {
        ...state,
        loading: false,
        shiftList: action.payload,
        receivedResponse: 200,
        isshiftListFetched: true,
      };
    case types.GET_ALL_SHIFTS_PENDING:
      return {
        ...state,
        loading: true,
        receivedResponse: false,
        isshiftListFetched: false,
      };
    case types.GET_ALL_SHIFTS_REJECTED:
      return {
        ...state,
        loading: false,
        receivedResponse: false,
        error: "An error occurred, please try again",
      };
    default:
      return state; // Return the state as is for unknown actions
  }
}
