import { combineReducers } from "redux";

import LoginReducer from "./LoginReducer";
import CompanyReducer from "./CompanyReducers";
import ShiftReducer from "./ShiftReducer";
export default combineReducers({
  LoginReducer,
  CompanyReducer,
  ShiftReducer,
});
