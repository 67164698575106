import React from "react";
import { useDispatch } from "react-redux"
import UserDropdown from "../common/UserDropdown";
import { logOut } from "../store/actions/LoginActions";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
export default function Navbar() {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const handleLogout = () => {
    try {
      dispatch(logOut())
      enqueueSnackbar("Logged out Successfully", { variant: "success" });
      navigate("/login");
    } catch (err) {
      enqueueSnackbar("Something went wrong. Please try again!", { variant: "error" });
    }
  }
  return (
    <>
      {/* Navbar */}
      <nav className="top-0 left-0 w-full z-10  md:flex-row md:flex-nowrap md:justify-start flex items-center p-4">
        <div className="w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4">
          {/* Brand */}
          <a
            className="text-blueGray-700 text-sm uppercase hidden lg:inline-block font-semibold"
            href="#pablo"
            onClick={(e) => e.preventDefault()}
          >
          </a>
          <ul className="flex-col md:flex-row list-none items-center hidden md:flex">
            <UserDropdown handleLogout={handleLogout} />
          </ul>
        </div>
      </nav>
      {/* End Navbar */}
    </>
  );
}
