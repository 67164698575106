import React, { useState, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux'
import axios from "axios";
import { serverAddress } from "../constants.js";
import Sidebar from "../containers/Sidebar.js";
import Navbar from "../components/Navbar.js";
import Footer from "../components/Footer.js";

const ProtectedRoute = ({ children }) => {
  const location = useLocation();
  const userDetails = useSelector((state) => state.LoginReducer.loginData)
  const token = userDetails ? userDetails.token : null;
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // New state to track loading

  useEffect(() => {
    const verifyToken = async () => {
      if (token) {
        try {
          let url = serverAddress + "/api/token/checkToken";
          const response = await axios.get(url, {
            headers: {
              Authorization: token,
            },
          });
          setIsLoggedIn(response.data.isLoggedIn);
        } catch (error) {
          console.error("Error verifying token:", error);
          setIsLoggedIn(false);
        }
      }
      setIsLoading(false); // Set loading to false after verification
    };

    verifyToken();
  }, [token]);

  // If still loading, don't make any decisions yet
  if (isLoading) {
    return null; // or a loading spinner
  }

  if (location.pathname === "/login" && isLoggedIn) {
    return <Navigate to="/dashboard" />;
  }

  if (!isLoggedIn && location.pathname !== "/login") {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100 h-full min-h-screen">
        <Navbar />
        <div className="px-4 md:px-10 mx-auto w-full">
          {children}
        </div>
        <Footer />
      </div>

    </>
  );
};

export default ProtectedRoute;
