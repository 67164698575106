import * as types from "../../constants";

let sessionValue = JSON.parse(localStorage.getItem("userDetails"));

const initialFetchDataState = {
  isLogin: false,
  isRegistered: false,
  isFound: false,
  isChanged: false,
  registrationData: "",
  loginData: sessionValue ? sessionValue : [],
  receivedResponse: sessionValue ? sessionValue.receivedResponse : false,
  message_error: sessionValue ? sessionValue.message_error : "",
  tokenValid: false,
};

export default function loginReducer(state = initialFetchDataState, action) {
  switch (action.type) {
    case types.LOGIN_DATA_FULFILLED:
      state = {
        ...state,
        isLogin: true,
        loginData: action.payload,
        receivedResponse: 200,
      };
      break;

    case types.LOGIN_DATA_PENDING:
      state = {
        ...state,
        isLogin: false,
        receivedResponse: false,
      };
      break;

    case types.LOGIN_DATA_REJECTED:
      state = {
        ...state,
        isFetched: false,
        receivedResponse: false,
        message_error: "Username or password invalid.",
      };
      break;

    case types.INITIATE_RESET_FULFILLED:
      state = {
        ...state,
        isFound: true,
        loginData: action.payload,
        receivedResponse: 200,
      };
      break;

    case types.INITIATE_RESET_PENDING:
      state = {
        ...state,
        isFound: false,
        receivedResponse: false,
      };
      break;

    case types.INITIATE_RESET_REJECTED:
      state = {
        ...state,
        isFetched: false,
        receivedResponse: false,
        message_error: "Username or email is not found.",
      };
      break;
    case types.RESET_PASSWORD_FULFILLED:
      state = {
        ...state,
        isChanged: true,
        loginData: action.payload,
        receivedResponse: 200,
      };
      break;

    case types.RESET_PASSWORD_PENDING:
      state = {
        ...state,
        isChanged: false,
        receivedResponse: false,
      };
      break;

    case types.RESET_PASSWORD_REJECTED:
      state = {
        ...state,
        isFetched: false,
        receivedResponse: false,
        message_error: "Something went wrong. Please try again",
      };
      break;

    case types.REGISTER_USER_FULFILLED:
      state = {
        ...state,
        isRegistered: true,
        registrationData: action.payload,
        receivedResponse: 200,
      };
      break;

    case types.REGISTER_USER_PENDING:
      state = {
        ...state,
        isRegistered: false,
        receivedResponse: false,
      };
      break;

    case types.REGISTER_USER_REJECTED:
      state = {
        ...state,
        isRegistered: false,
        receivedResponse: false,
        message_error: "Please provide all the details correctly",
      };
      break;

    case types.VALIDATE_TOKEN_FULFILLED:
      state = {
        ...state,
        tokenValid: true,
        isLogin: true,
        receivedResponse: 200,
      };
      break;

    case types.VALIDATE_TOKEN_PENDING:
      state = {
        ...state,
        tokenValid: false,
        receivedResponse: false,
      };
      break;

    case types.VALIDATE_TOKEN_REJECTED:
      state = {
        ...state,
        tokenValid: false,
        receivedResponse: false,
        isLogin: false,
      };
      break;
    case types.LOGOUT:
      state = {
        isLogin: false,
        loginData: [],
        receivedResponse: false,
        message_error: "",
      };
      break;
    default:
      state = {
        ...state,
      };
  }

  return state;
}
