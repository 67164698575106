import * as types from "../../constants";

const initialState = {
  loading: true,
  parttimeData: [],
  parttimeAddResponse: [],
  isUpdated: false,
  parttimeList: [],
  message_error: "",
  receivedResponse: false,
  isparttimeAdded: false,
  isparttimeDeleted: false,
  isparttimeListFetched: false,
};

export default function CompanyReducer(state = initialState, action) {
  switch (action.type) {
    case types.CREATE_NEW_PARTTIME_FULFILLED:
      return {
        ...state,
        loading: false,
        parttimeAddResponse: action.payload,
        receivedResponse: 200,
        isparttimeAdded: true,
      };
    case types.CREATE_NEW_PARTTIME_PENDING:
      return {
        ...state,
        loading: true,
        receivedResponse: false,
        isparttimeAdded: false,
      };
    case types.CREATE_NEW_PARTTIME_REJECTED:
      return {
        ...state,
        loading: false,
        receivedResponse: false,
        error: "An error occurred, please try again",
      };
    case types.GET_ALL_PARTTIME_FULFILLED:
      return {
        ...state,
        loading: false,
        parttimeList: action.payload,
        receivedResponse: 200,
        isparttimeListFetched: true,
      };
    case types.GET_ALL_PARTTIME_PENDING:
      return {
        ...state,
        loading: true,
        receivedResponse: false,
        isparttimeListFetched: false,
      };
    case types.GET_ALL_PARTTIME_REJECTED:
      return {
        ...state,
        loading: false,
        receivedResponse: false,
        error: "An error occurred, please try again",
      };
    case types.UPDATE_PARTTIME_FULFILLED:
      return {
        ...state,
        loading: false,
        receivedResponse: 200,
        isUpdated: true,
      };
    case types.UPDATE_PARTTIME_PENDING:
      return {
        ...state,
        loading: true,
        receivedResponse: false,
        isUpdated: false,
      };
    case types.UPDATE_PARTTIME_REJECTED:
      return {
        ...state,
        loading: false,
        receivedResponse: false,
        error: "An error occurred, please try again",
      };
    default:
      return state; // Return the state as is for unknown actions
  }
}
