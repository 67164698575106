import React from "react";

export default function Footer() {
    return (
        <footer className="block py-4">
            <div className="container mx-auto px-4">
                <hr className="mb-4 border-b-1 border-blueGray-200" />
                <div className="flex flex-wrap items-center justify-center">
                    <div className="w-full px-4 text-center">
                        <div className="text-sm text-blueGray-500 font-semibold py-1">
                            Developed and maintained by
                            <a
                                href="https://www.linkedin.com/in/udaikiranravada/"
                                target="_blank"
                                rel="noreferrer"
                                className="text-blueGray-500 hover:text-blueGray-700 text-sm font-semibold py-1"
                            >
                                {" "} Udaikiran Ravada
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
