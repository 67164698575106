import React from "react";


const AuthRoutes = ({ children }) => {
    return (
        <section className="relative w-full h-full py-40 min-h-screen">
            <div
                className="absolute top-0 w-full h-full bg-blueGray-800 bg-no-repeat bg-full"
                // style={{
                //     backgroundImage:
                //         "url(" + require("../common/img/register_bg_2.png") + ")",
                // }}
            ></div>
            {children}
        </section>
    )
};

export default AuthRoutes;
